<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="col-lg-12" style="top: -180px;overflow: hidden;">

            <div class="left_about">
                <img alt="" src="../../../assets/image/about/1.webp">
            </div>
            <div style="position: relative">
                <div class="right_about">

                    <h1 class="about01">{{$t('AboutMessage.About1')}}</h1>

                    <div class="right_world">
                        <p class="about01">{{$t('AboutMessage.About2')}}  </p>
                        <p class="about01">{{$t('AboutMessage.About3')}}</p>
                        <p class="about01">{{$t('AboutMessage.About4')}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12" style="margin-top: -150px;position: relative">
            <div class="left_img">
                <h1 class="about02" style="margin-top: 45px;color: black;text-align: center;font-weight: 800">
                    {{$t('AboutMessage.About5')}}</h1>
                <h5 class="about02"
                    style="margin-top: 45px;color: black;text-align: center;display: inline-block;width: 380px;margin-left: 80px;font-weight: 700">
                    {{$t('AboutMessage.About6')}}</h5>
                <p class="about02">1.{{$t('AboutMessage.About7')}}</p>
                <p class="about02">2.{{$t('AboutMessage.About8')}}</p>
                <p class="about02">3.{{$t('AboutMessage.About9')}}</p>
                <p class="about02">4.{{$t('AboutMessage.About10')}}</p>
                <p class="about02">{{$t('AboutMessage.About11')}}</p>

            </div>
            <div class="right_img">
                <img alt=""
                     src="../../../assets/image/about/2.webp">
            </div>
        </div>
        <Bottom style="margin-left: -45px"></Bottom>
    </div>


</template>

<script>
    import {Head, Bottom} from '../commponents'

    export default {
        name: "About",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        data() {
            return {
                url: "../../../assets/img/blockchainis.jpg",
                show: false,
            }
        },
        mounted() {

        },
        created() {
            $(function () {
                $('.about01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 350) {
                        $('.about02').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }

                });


            })
        }
    }

    $(function () {
        $('.about01').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 350) {
                $('.about02').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }

        });


    })

</script>

<style scoped>
    .about01 {
        opacity: 0;
        position: relative;
        top: -15px;
    }

    .about02 {
        opacity: 0;
        position: relative;
        top: 15px;
    }

    .col-lg-12 .left_about {
        float: left;
        width: 760px;
        overflow: hidden;
        margin-left: -25px;
    }

    .left_about img {
        height: 570px;
        width: 760px;
    }

    .col-lg-12 .right_about {
        max-width: 730px;
        margin-left: 750px;
        position: absolute;

    }

    .right_about h1 {
        color: black;
        text-align: center;
        margin-top: 100px;
        margin-bottom: 20px;
        font-weight: 800;
    }

    .right_about .right_world {
        height: 300px;


    }

    .right_world p::before {
        content: "";
        height: 5px;
        width: 5px;
        position: absolute;
        background-color: black;
        border-radius: 50%;
        margin-left: -15px;
        margin-top: 9px;
    }

    .right_about .right_world p {
        color: black;
        line-height: 25px;
        margin-left: 17px;
        font-weight: 700;

    }

    .left_img {
        height: 500px;
        width: 500px;
        background-color: #d4d4d4;
        position: absolute;
        margin-left: 320px;
        z-index: 9;
        margin-top: 150px;

    }

    .left_img p {
        color: black;
        margin-left: 120px;
        margin-top: 15px;
        font-weight: 700;
    }

    .right_img {
        margin-left: 750px;
    }

    .right_img img {
        height: 737px;
        width: 520px;

    }

</style>